/* PRIMARY LAYOUT ELEMENTS
----------------------------------------------------------*/
/* Importing Bootstrap SCSS file. */
@import "~bootstrap/scss/bootstrap";

body {
  font-size: 1rem;
  font-family: "Helvetica Neue", "Lucida Grande", "Segoe UI", Arial, Helvetica,
    Verdana, sans-serif;
  margin: 0px;
  padding: 0px;
  height: 100%;
  background-color: #000000;
}

img {
  border: 0;
}

a {
  color: white;
}

#holding {
  width: 100%;
  height: 100%;
  background: url("./assets/main/holding_image.jpg") center center no-repeat;
}

.main-container {
  margin: 20px auto;
  text-align: left;
}
